import React from 'react'
import { twMerge } from 'tailwind-merge'

export type TypographyVariants =
  | 'body1'
  | 'body2'
  | 'body3'
  | 'label1'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'error'
  | 'custom'

interface TypographyProps {
  variant?: TypographyVariants
  className?: string
  children?: React.ReactNode
  id?: string
  onClick?: () => void
}

export default function Typography({
  children,
  variant = 'body1',
  className = '',
  id,
  onClick,
}: TypographyProps) {
  switch (variant) {
    case 'body1':
      return (
        <span
          className={twMerge(
            `m-0 text-base font-normal leading-normal text-black ${className}`
          )}
          id={id}
          onClick={onClick}
        >
          {children}
        </span>
      )
    case 'body2':
      return (
        <p
          className={twMerge(
            `m-0 font-sans font-normal text-[#424242] ${className}`
          )}
          id={id}
          onClick={onClick}
        >
          {children}
        </p>
      )
    case 'body3':
      return (
        <p
          className={twMerge(
            `m-0 font-sans text-lg font-medium text-black ${className}`
          )}
          id={id}
          onClick={onClick}
        >
          {children}
        </p>
      )
    case 'label1':
      return (
        <p
          className={twMerge(
            `m-0 text-base font-normal leading-normal text-[#858585] ${className}`
          )}
          id={id}
          onClick={onClick}
        >
          {children}
        </p>
      )
    case 'h1':
      return (
        <h1
          className={twMerge(
            `text m-0 font-sans text-[30px] font-medium leading-[45px] text-black sm:text-[40px] sm:leading-[50px] ${className}`
          )}
          id={id}
          onClick={onClick}
        >
          {children}
        </h1>
      )
    case 'h2':
      return (
        <h2
          className={twMerge(
            `m-0 font-sans text-[24px] font-medium leading-[28px] text-black ${className}`
          )}
          id={id}
          onClick={onClick}
        >
          {children}
        </h2>
      )
    case 'h3':
      return (
        <h3
          className={twMerge(
            `m-0 font-sans text-[18px] font-medium leading-[28px] text-[#808080] ${className}`
          )}
          id={id}
          onClick={onClick}
        >
          {children}
        </h3>
      )
    case 'error':
      return (
        <span
          className={twMerge(`text-sm text-[#FF0000] ${className}`)}
          id={id}
          onClick={onClick}
        >
          {children}
        </span>
      )
    case 'custom':
      return (
        <span className={className} id={id} onClick={onClick}>
          {children}
        </span>
      )
  }
}
