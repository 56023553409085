import { LogoColor } from 'shared/types/frontendTypes'
import Logo from './Logo'

interface LogoWithTextProps {
  color?: LogoColor
  dark?: boolean
  className?: string
  logoClassName?: string
  text?: string
}

export default function LogoWithText({
  color,
  dark = false,
  className,
  logoClassName,
  text = 'gift cards',
}: LogoWithTextProps) {
  return (
    <div className={className}>
      <div className="relative flex flex-row items-center gap-1 whitespace-nowrap text-nowrap">
        <div className="mb-[6px]">
          <Logo color={color} className={`w-[90px] ${logoClassName}`} />
        </div>
        <span
          className={`text-[16px] font-medium ${dark ? 'text-white' : 'text-black'}`}
        >
          {text}
        </span>
      </div>
    </div>
  )
}
