'use client'
import React, { useState } from 'react'

import Drawer from '@mui/material/Drawer'
import Sidebar from './Sidebar'
import MenuIcon from 'components/common/icons/Menu'

export default function HamburgerContent({}) {
  const [open, setOpen] = useState(false)

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      setOpen(open)
    }

  return (
    <>
      <MenuIcon
        onClick={toggleDrawer(true)}
        className="size-6 cursor-pointer fill-[#323232] transition-colors hover:fill-black"
      />
      <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>
        <Sidebar onClose={toggleDrawer(false)} />
      </Drawer>
    </>
  )
}
