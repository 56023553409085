import { MouseEventHandler } from 'react'
import Link from 'next/link'

import { useUser } from '../../lib/auth/UserContext'
import CloseIcon from '@mui/icons-material/Close'
import DeprecatedCTAButton from 'components/common/DeprecatedCTAButton'
import Typography from 'components/common/Typography'
import { twMerge } from 'tailwind-merge'
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos'
import Button from 'components/common/input/Button'

export default function Sidebar({
  onClose,
}: {
  onClose: MouseEventHandler<SVGSVGElement>
}) {
  const { user } = useUser()

  const header = (title: string, className?: string) => (
    <div
      className={twMerge(
        `mt-[50px] border-x-0 border-b border-t-0 border-solid border-[#E9E9E9] pb-2 ${className}`,
      )}
    >
      <Typography className="text-[16px] text-[#858585]">{title}</Typography>
    </div>
  )

  const entry = (title: string, url: string) => (
    <Link href={url} passHref className={`text-black no-underline`}>
      <div
        className={` flex flex-row items-center justify-between border-x-0 border-b border-t-0 border-solid border-[#E9E9E9] py-4 align-middle`}
      >
        <Typography className="text-[24px]">{title}</Typography>
        <ArrowForwardIos className="px-1 text-[#000]" />
      </div>
    </Link>
  )

  return (
    <div className="w-[100vw] sm:w-[600px]">
      <div className="m-6 sm:mx-[50px]">
        <div
          className={`flex flex-row ${
            user ? 'mb-8 justify-between align-top' : 'justify-end'
          }`}
        >
          {user && (
            <div className="flex flex-col">
              <Typography className="text-[20px]">{`${user.names.givenName} ${user.names.familyName}`}</Typography>
              <Typography className="text-[14px]">
                {user.email?.primary}
              </Typography>
            </div>
          )}
          <CloseIcon
            onClick={onClose}
            sx={{ color: '#000' }}
            className="h-[30px] w-[30px] cursor-pointer"
          />
        </div>
        <div className="flex flex-col">
          {header('My Gifts', 'mt-0')}
          {user ? (
            <>
              {entry('Gifts Sent', '/gifts/sent')}
              {entry('Gifts Received', '/gifts/received')}
            </>
          ) : (
            <>
              {entry('Gifts Sent', '/login?redirect=/gifts/received')}
              {entry('Gifts Received', '/redeem?redirect=/gifts/received')}
            </>
          )}
          {header('On Me')}
          {entry('All gift cards', '/shop-gift-cards')}
          {entry('Blog', '/blog')}
          {entry('FAQ / Support', '/faq')}
          {entry('Terms and Conditions', '/terms-of-service')}
          {header('Account')}
          {user ? (
            <>
              {entry('Account Information', '/account')}
              {entry('Logout', '/logout')}
            </>
          ) : (
            <>
              {entry('Login / Sign Up', '/login')}
              {entry('Account Information', '/login?redirect=/account')}
            </>
          )}
        </div>
        <div className="mb-4 mt-10 flex flex-row justify-center">
          <Button primary href={'/shop-gift-cards'}>
            Send gift card
          </Button>
        </div>
      </div>
    </div>
  )
}
